"use client"
import { useAppSelector } from "@/app/redux/hooks/reduxHooks"
import { useRouter, useSearchParams } from "next/navigation"
import { useEffect } from "react"

export default function Home() {
    const auth = useAppSelector((state) => state.auth)
    const router = useRouter()
    const searchParams = useSearchParams()

    useEffect(() => {
        if (auth.isUserSignedIn) {
            router.push("/dashboard")
        }
    }, [router, auth.isUserSignedIn])

    return (
        <main>
            <section className="bg-[url('/background.webp')] py-10 h-min-screen bg-cover bg-right-bottom">
                <section className="mx-auto max-w-[1200px] p-5">
                    <h1 className="text-white text-lg py-8">METALCORE PORTAL</h1>
                    <p className="font-thin text-white max-w-[800px]">
                        The MetalCore Portal is the central hub for players to access all features and information
                        related to the MetalCore game universe. It serves as the gateway for both new and returning
                        players to engage with the Missions game, explore various features, and manage their accounts.
                        Currently, only the Missions game is live, but additional features like leaderboards and the
                        Barony system will be available soon.
                    </p>
                </section>
            </section>
        </main>
    )
}
